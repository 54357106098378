import React from 'react'
import PropTypes from 'prop-types'
import EmailCaptureModal from './email-capture-modal'
import Footer from './footer'
import Header from './header'
import './layout.css'

const Layout = ({ children, location = {}, useStaticPageStyling = false }) => {
    let customMainStyling = {}

    if (useStaticPageStyling) {
        customMainStyling = {
            justifyContent: 'center',
        }
    }

    return (
        <>
            <EmailCaptureModal />
            <Header siteTitle="VideoElephant" currentPath={location.pathname} />
            <main style={customMainStyling}>{children}</main>
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
