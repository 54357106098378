import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { setGaScreenName } from '../utils'

export default function StaticPageTemplate({ data }) {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark

    useEffect(() => {
        if (frontmatter.title) {
            setGaScreenName(frontmatter.title)
        }
    }, [frontmatter.title])

    return (
        <Layout useStaticPageStyling>
            <SEO title={frontmatter.title} />
            <StaticPageContainer>
                <div className="static-page">
                    <h1>{frontmatter.title}</h1>
                    <div className="static-page-body" dangerouslySetInnerHTML={{ __html: html }} />
                </div>
            </StaticPageContainer>
        </Layout>
    )
}

const StaticPageContainer = styled.div`
    max-width: 755px;
    margin-top: 152px;
    margin-bottom: 76px;

    h1 {
        color: rgb(255, 255, 255);
        font-size: 36px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;
        line-height: 44px;
        margin-bottom: 20px;
    }

    h2,
    h3 {
        color: rgb(255, 255, 255);
        font-size: 24px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        letter-spacing: 0.14px;
        line-height: 28px;
        margin-top: 48px;
        margin-bottom: 20px;
    }

    h4,
    h5 {
        color: rgb(255, 255, 255);
        font-family: 'Source Sans Pro', sans-serif;
    }
    h5 {
        font-size: 16px;
    }

    a,
    p,
    li {
        font-size: 16px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 20px;
    }

    li,
    p {
        color: rgb(255, 255, 255, 0.7);
    }

    ul,
    p {
        margin-bottom: 16px;
    }

    a {
        color: white;
        opacity: 1;
        text-decoration: underline;
        padding: 0 2px;

        &:hover {
            text-decoration: none;
        }
    }

    @media screen and (max-width: 672px) {
        max-width: unset;
        margin-top: 76px;
        margin-bottom: 48px;
        margin-left: var(--discover-page-margin-mobile);
        margin-right: var(--discover-page-margin-mobile);

        h1 {
            font-size: 20px;
            letter-spacing: 0.11px;
            line-height: 28px;
            margin-bottom: 16px;
        }

        h2,
        h3 {
            font-size: 17px;
            letter-spacing: 0.1px;
            line-height: 24px;
            margin-top: 32px;
            margin-bottom: 16px;
        }

        a,
        p,
        li {
            font-size: 15px;
            letter-spacing: 0px;
            line-height: 20px;
        }

        ul,
        p {
            margin-bottom: 12px;
        }
    }
`

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                slug
                title
            }
        }
    }
`
